<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol sm="10" md="8" lg="6" xl="5">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="submit">
                  <h1>Вход</h1>
                  <p class="text-muted">Войти в аккаунт</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username email"
                    v-model="form.username"
                    name="username"
                  >
<!--                    <template #prepend-content><CIcon name="cil-user"/></template>-->
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    name="password" v-model="form.password"
                  >
<!--                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>-->
                  </CInput>
                  <CRow v-if="showError">
                    <CCol col="12" class="text-left">
                        <p id="error">Ошибка</p>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">Войти</CButton>
                    </CCol>
                    <!--<CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol>-->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import md5 from "md5";

export default {
    name: "Login",
    components: {},
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            showError: false
        };
    },
    methods: {
        ...mapActions(["LogIn"]),
        submit() {


          const User = new FormData();
          User.append('action', 'login');
          User.append("login", this.form.username);
          User.append("pass", md5(this.form.password));

          axios({
            method: "post",
            url: "https://kalish.uz/cms_local.php",
            data: User
          })
            .then((r) => {
              if (r.data.length > 0) {
                this.LogIn(r.data[0]);
                this.showError = false;
                this.$router.push({path: `/`}).catch((e) => {
                    console.error(e)
                })
              } else {
                this.showError = true
              }
              //Perform Success Action
              // this.getDataFromApi()
            })
            .catch((error) => {
              console.log(error)
              this.showError = true
            }).finally(() => {
            // this.getDataFromApi()
          });

            // const User = new FormData();
            // User.append("username", this.form.username);
            // User.append("password", this.form.password);
            // try {
            //     await this.LogIn(User);
            //     this.$router.push("/posts");
            //     this.showError = false
            // } catch (error) {
            //     this.showError = true
            // }
        },
    },
};
</script>
